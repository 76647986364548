import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AuthService, AuthServiceConfig, GoogleLoginProvider} from 'angularx-social-login';
import {HttpClientModule} from '@angular/common/http';
import { HtmlPipe } from './html.pipe';

const config = new AuthServiceConfig([{
  id: GoogleLoginProvider.PROVIDER_ID,
  provider: new GoogleLoginProvider('379063540804-85tc3pnei3u2n4qjht6jtl8gc23rn97q.apps.googleusercontent.com')
}]);

@NgModule({
  declarations: [
    AppComponent,
    HtmlPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [
    {
      provide: AuthService,
      useValue: new AuthService(config)
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
