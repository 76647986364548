import {Component, isDevMode, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService, GoogleLoginProvider} from 'angularx-social-login';

export function isInDevMode(): boolean {
  return isDevMode();
}

const cacheTtl = 1000 * 60 * 30;
const googleLoginOptions = {ux_mode: 'redirect', prompt: 'consent', scope: 'profile'};
// const upstreamUrl = 'http://localhost:3001/homepage/data'
const upstreamUrl = 'https://jensen-integration.appspot.com/homepage/data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'jensen-homepage';
  data;
  idToken;
  icons = [];

  constructor(
    private auth: AuthService,
    private httpClient: HttpClient
  ) {
    const matchIdToken = window.location.href.match(/id_token=(.+)&login_hint/i);
    if (matchIdToken) this.idToken = matchIdToken.pop();
  }

  async ngOnInit() {
    const now = new Date();
    if (this.idToken) {
      localStorage.setItem('idToken', this.idToken);
      this.invalidate(this.idToken);
    } else {
      const date = new Date(localStorage.getItem('cacheTime'));
      if (now.getTime() - date.getTime() > cacheTtl) {
        if (!await this.invalidate())
          this.auth.signIn(GoogleLoginProvider.PROVIDER_ID, googleLoginOptions);
      } else
        try {
          this.data = JSON.parse(localStorage.getItem('cacheData'));
          this.data.linkIconSet.forEach(e => {
            this.icons = this.icons.concat(e);
          });
        } catch (e) {
          this.auth.signIn(GoogleLoginProvider.PROVIDER_ID, googleLoginOptions);
        }
    }
  }

  invalidate(idToken?): Promise<boolean> {
    if (!idToken) idToken = localStorage.getItem('idToken');
    return new Promise<boolean>(resolve => {
      this.httpClient.request('GET', upstreamUrl, {headers: {authorization: `Bearer ${idToken}`}, withCredentials: true})
        .subscribe(data => {
          this.data = data;
          localStorage.setItem('cacheTime', new Date().toISOString());
          localStorage.setItem('cacheData', JSON.stringify(this.data));
          this.icons.length = 0;
          this.data.linkIconSet.forEach(e => {
            this.icons = this.icons.concat(e);
          });
          resolve(true);
        }, err => {
          console.error(err);
          resolve(false);
        });
    });
  }
}

