<div class="{{data && 'fadein' || 'invsibile'}} noselect" *ngIf="data">
  <div class="logo">
    <img (click)="invalidate()" src="assets/img/logga-vit-100.png"/>
  </div>

  <div class="pagecenter">
    <div class="content">
      <div class="item" *ngFor="let n of icons">
        <a href="{{n.url}}">
          <div class="circle" style="background-color: {{ n.color }}">
            <img src="{{n.image_path}}">
          </div>

          <p>{{n.text}}</p>
        </a>
      </div>
    </div>

    <div class="links" *ngIf="data.links.length">
      <div *ngFor="let m of data.links" class="f-item">
        <a class="pytte" href="{{m.url}}">
          <img class="pytte" src="{{m.image_path}}">
          <p class="pytte">{{m.text}}</p>
        </a>
      </div>
    </div>

    <div class="messages" *ngIf="data.messages.length">
      <div *ngFor="let m of data.messages" class="message">
        <p [innerHtml]="m.message | html"></p>
      </div>
    </div>

    <div class="iframe">
      <iframe class="invsibile"
              id="mentaltraning" width="90%" height="20" scrolling="no"
              frameborder="no"
              src=""
              allowtransparency="true"
              onload="invalidateIFrame()"
      ></iframe>
    </div>
  </div>
</div>
